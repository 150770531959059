<template>
  <div class="relative min-h-[250px]">
    <section
      class="flex justify-between items-start lg:items-center flex-col md:flex-row"
    >
      <div>
        <p class="text-purpleDark m-0 p-0 font-mulish">
          Welcome {{ $store.state.admin.sessionName }}
        </p>
        <h3 class="text-purpleDark font-bold text-lg m-0 p-0 font-mulish">
          Dashboard Overview
        </h3>
      </div>

      <div
        class="p-3 bg-white rounded border border-gray-100 flex gap-3 items-center justify-end"
      >
        <label
          for="staffType"
          class="font-mulish text-darkBlue text-xs font-semibold"
        >
          View Staff Category By:
        </label>

        <v-select
          id="staffType"
          label="name"
          :reduce="(b) => b._id"
          :options="[
            { name: 'All Category', _id: '' },
            ...$store.state.admin.staffTypes,
          ]"
          v-uppercase
          v-model="staffTypeId"
          placeholder="-- Select Staff Category --"
          @input="getData"
        ></v-select>
      </div>
    </section>

    <div
      v-if="getting"
      class="absolute inset-0 h-full w-full bg-black/30 z-2222 flex items-center justify-center"
    >
      <spinner class="h-20 w-20"></spinner>
    </div>

    <section
      class="grid lg:grid-cols-6 md:grid-cols-3 xs:grid-cols-1 gap-4 mt-10"
    >
      <SmallCard
        v-for="(item, i) in dashboardItems"
        :key="i"
        :icon-name="item.iconName"
        :icon-bg="item.iconBg"
        :icon-color="item.iconColor"
        :count="item.count"
        :text="item.text"
      />
    </section>
  </div>
</template>

<script>
import SmallCard from "@/components/dashboard/element/SmallCard.vue";
export default {
  name: "TopSection",

  components: {
    SmallCard,
  },

  props: {
    titleAndName: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      getting: false,
      staffTypeId: null,
      dashboardItems: [
        {
          iconBg: "bg-[#660EF71A]",
          iconColor: "text-[#660EF7]",
          iconName: "bi:wallet",
          text: "Total Records",
          count: 0,
          type: "allRecords",
        },
        {
          iconBg: "bg-[#CF89001A]",
          iconColor: "text-[#CF8900]",
          iconName: "heroicons:building-office-2",
          text: "MDA’s",
          count: 0,
          type: "allMdas",
        },

        {
          iconBg: "bg-[#C948481A]",
          iconColor: "text-[#C94848]",
          iconName: "mingcute:department-line",
          text: "Departments",
          count: 0,
          type: "allDepartments",
        },
        {
          iconBg: "bg-[#0090FF1A]",
          iconColor: "text-[#0090FF]",
          iconName: "ph:files",
          text: "Completed Online Verification",
          count: 0,
          type: "onlineVerificationCompleted",
        },
        {
          iconBg: "bg-[#0193851A]",
          iconColor: "text-[#019385]",
          iconName: "ic:outline-verified",
          text: "Verified Records",
          count: 0,
          type: "completedRecords",
        },
        {
          iconBg: "bg-[#17517E1A]",
          iconColor: "text-[#17517E]",
          iconName: "codicon:unverified",
          text: "Unverified Records",
          count: 0,
          type: "unCompleteRecords",
        },
      ],
    };
  },

  created() {
    this.getData();
  },

  methods: {
    async getData(val = null) {
      try {
        this.getting = true;
        let url = "/adhoc/dashboard";
        url += val ? `?staffTypeId=${val}` : "";

        const res = await this.$http.get(url, {
          headers: { noauth: true },
        });
        this.getting = false;
        if (!res) {
          return;
        }

        const { data } = res.data;

        for (let i = 0; i < this.dashboardItems.length; i++) {
          const el = this.dashboardItems[i];

          el.count = data[el.type];
        }
      } catch (error) {
        console.log({ error });
      }
    },
  },
};
</script>

<style scoped>
#staffType {
  height: 40px !important;
  width: 300px;
}

#staffType .vs__search::placeholder {
  font-weight: 700 !important;
  font-size: 0.75rem !important;
  line-height: 0.875rem !important;
  color: #1f1d1d !important;
}
#staffType .vs__dropdown-menu {
  background: #f9f9f9;
  color: #757474;
  font-size: 0.7rem !important;
  line-height: 0.9rem;
  font-family: "Mulish";
  text-transform: uppercase;
  font-variant: small-caps;
  width: 300px !important;
}

.vs__dropdown-toggle {
  padding: 0rem !important;
}
</style>
