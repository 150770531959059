<script>
import { Icon } from "@iconify/vue2";
export default {
  name: "SearchCard",
  components: { Icon },
  props: {
    firstName: String,
    lastName: String,
    verificationCode: String,
    photo: String,
    phone: String,
  },
  data() {
    return {};
  },
  methods: {
    handleClick() {
      this.$emit("handleClick");
    },
  },
};
</script>

<template>
  <div
    class="h-[220px] w-[220px] bg-white shadow rounded flex justify-between flex-col text-dkgray pt-2"
  >
    <div class="flex flex-col items-center justify-center w-full grow">
      <img
        v-if="!photo"
        src="@/assets/images/null_female.png"
        class="h-20 w-20 rounded-full object-cover"
        alt=""
      />
      <img v-else :src="photo" class="h-20 w-20 rounded-full" alt="" />
      <div class="my-2">
        <span class="mr-2 font-bold">{{ firstName }}</span>
        <span class="font-bold">{{ lastName }}</span>
      </div>
      <p class="m-0 text-xs">
        <span>VN: </span>
        <span>{{ verificationCode }}</span>
      </p>
      <p class="m-0 text-xs">
        <span>Tel: </span>
        <span>{{ phone }}</span>
      </p>
    </div>
    <button
      @click="handleClick"
      class="w-full p-3 text-blue-500 bg-blue-100 font-semibold text-sm flex items-center justify-center"
    >
      View Details
      <Icon icon="solar:arrow-right-outline" class="text-2xl mr-1" />
    </button>
  </div>
</template>

<style scoped></style>
