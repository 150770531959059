<script>
// import Spinner from "@/components/app/Spinner.vue";
import StaffCard from "./StaffCard.vue";
import LoadOverlay from "@/components/app/LoadOverlay.vue";

export default {
  name: "StaffList",
  components: { StaffCard, LoadOverlay },
  props: { data: { type: Array, required: true } },
  data() {
    return {
      getting: false,
      sending: false,
      notFound: true,
      printout: null,
    };
  },
  methods: {
    closePrintoutModal() {
      this.printout = null;
    },
    async openStaffVerificationSlip(staff) {
      this.printout = null;
      this.getting = true;

      const res = await this.$http.get(
        `/employee/${staff.verificationCode}?baseUrl=${window.location.origin}/staff-printout&staffType=${staff.staffType}`,
        {
          headers: { noauth: true },
        }
      );

      if (!res) {
        this.getting = false;
        return;
      }

      this.getting = false;
      // this.notFound = false;

      const { data } = res;
      if (!data) {
        this.$swal({
          text: "Error getting staff verification printout.",
          icon: "error",
        });

        return;
      }
      this.printout = data;
    },
  },
};
</script>

<template>
  <div class="w-full h-full mt-10 flex flex-wrap gap-4">
    <StaffCard
      v-for="(staff, i) in data"
      :key="i"
      @handleClick="openStaffVerificationSlip(staff)"
      :first-name="staff.firstName"
      :last-name="staff.lastName"
      :verification-code="staff.verificationCode"
      :phone="staff.phone"
      :photograph="staff.photograph"
    />

    <LoadOverlay v-if="getting" />

    <div
      v-if="printout"
      class="fixed top-0 left-0 bottom-0 w-full h-screen z-100"
    >
      <div class="h-screen w-full relative overflow-y-scroll">
        <div class="h-screen w-full bg-gray-700 opacity-70 fixed inset-0"></div>
        <div class="top-36 right-64 w-44 fixed z-100">
          <button
            class="border rounded text-white cursor-pointer font-semibold px-10 py-2"
            @click="closePrintoutModal"
          >
            Close
          </button>
        </div>
        <div class="w-full absolute top-0 left-0">
          <div
            class="w-2/3 mx-auto scale-75 bg-white rounded-lg"
            v-html="printout"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
