<script>
import { Icon } from "@iconify/vue2";

export default {
  name: "CustomSearch",
  components: { Icon },
  data() {
    return {
      search: "",
    };
  },
  methods: {
    handleInput() {
      this.$emit("handleInput", this.search);
    },
  },
};
</script>

<template>
  <form @submit.prevent="handleInput">
    <span class="flex bg-saeagray w-[300px] rounded items-center px-3">
      <Icon icon="basil:search-solid" class="text-bold text-lg w-7" />
      <input
        v-model="search"
        type="text"
        placeholder="search with verification code or name"
        class="outline-none border-none bg-transparent h-9 pl-1 placeholder text-sm w-full"
      />
      <button
        type="submit"
        v-if="search.length >= 3"
        class="text-[10px] bg-oneGreen text-white rounded px-2 py-0.5"
      >
        search
      </button>
    </span>
  </form>
</template>
