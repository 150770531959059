<script>
import { Icon } from "@iconify/vue2";
import SearchCard from "./element/authorized/SearchCard.vue";

export default {
  name: "SearchResultOverlay",
  components: { SearchCard, Icon },
  props: {
    data: { type: Array, required: true },
    staffCount: { type: Number, required: true },
  },
  data() {
    return {
      selectedStaff: null,
      showPrintout: false,
      printout: null,
      getting: false,
    };
  },
  methods: {
    handleBack() {
      this.$emit("handleBack");
    },
    closePrintoutModal() {
      this.selectedStaff = null;
      this.showPrintout = false;
    },

    async openStaffVerificationSlip(staff) {
      this.printout = null;
      this.showPrintout = true;
      this.getting = true;

      const res = await this.$http.get(
        `/employee/${staff.verificationCode}?baseUrl=${window.location.origin}/staff-printout&staffType=${staff.staffType}`,
        {
          headers: { noauth: true },
        }
      );

      if (!res) {
        this.showPrintout = false;
        this.getting = false;
        return;
      }

      this.getting = false;

      // this.notFound = false;

      const { data } = res;
      if (!data) {
        this.showPrintout = false;
        this.getting = false;
        this.$swal({
          text: "Error getting staff verification printout.",
          icon: "error",
        });

        return;
      }
      this.printout = data;
    },
  },
};
</script>

<template>
  <div class="h-full w-full absolute top-0 left-0 bg-gray-50 p-5 rounded z-13">
    <div class="flex items-center">
      <button
        @click="handleBack"
        class="px-4 py-2 rounded border border-blue-500 bg-blue-200 font-semibold text-xs font-mulish text-blue-600 flex justify-center items-center mr-2"
      >
        <Icon icon="solar:arrow-left-outline" class="text-2xl mr-1" /> Return
        Home
      </button>

      <span class="ml-2"
        >Staff Count: <span>({{ staffCount }})</span></span
      >
    </div>

    <div class="flex gap-4 mt-4 flex-wrap overflow-y-scroll">
      <SearchCard
        v-for="(staff, i) in data"
        :key="i"
        @handleClick="openStaffVerificationSlip(staff)"
        :first-name="staff.firstName"
        :last-name="staff.lastName"
        :phone="staff.phone"
        :photo="staff.photograph"
        :verification-code="staff.verificationCode"
      />
    </div>

    <div v-if="showPrintout">
      <LoadOverlay v-if="getting" />
      <div
        v-if="printout"
        class="fixed top-0 left-0 w-full h-full z-100 items-center"
      >
        <div class="h-full w-full relative">
          <div
            class="h-screen w-full bg-gray-700 opacity-70 fixed inset-0"
          ></div>
          <div class="top-36 right-64 w-44 fixed z-100">
            <button
              class="border rounded text-white cursor-pointer font-semibold px-10 py-2"
              @click="closePrintoutModal"
            >
              Close
            </button>
          </div>
          <div class="w-full absolute top-0 left-0">
            <div
              class="w-2/3 mx-auto scale-75 bg-white rounded-lg"
              v-html="printout"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
