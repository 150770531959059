<template>
  <div
    class="flex gap-2 border rounded-lg border-gray-200 py-2 px-3 text-xs cursor-pointer font-mulish"
  >
    <button
      v-for="(tab, i) in tabs"
      :key="i"
      :class="`${
        tab.slug == activeBtn ? 'active' : 'text-dkgray'
      } py-1.5 px-4 rounded font-semibold max-w-max text-sm`"
      @click="tabClick(tab)"
    >
      {{ tab.label }}
    </button>
  </div>
</template>

<script>
export default {
  name: "MidTab",

  props: {
    value: {
      type: String,
      default: "staff",
    },
  },

  data() {
    return {
      activeBtn: this.value,
      tabs: [
        {
          label: "Staff Information",
          slug: "staff",
        },
        {
          label: "Pension Information",
          slug: "pension",
        },
      ],
    };
  },

  methods: {
    tabClick(tab) {
      this.activeBtn = tab.slug;
      this.$emit("input", this.activeBtn);
    },
  },
};
</script>

<style scope>
.active {
  @apply bg-green-100 text-green-700 border border-green-300;
}
</style>
