<template>
  <section class="fixed top-0 left-0 h-full w-full z-100">
    <div class="relative h-full w-full">
      <div class="h-full w-full bg-gray-500 opacity-90"></div>
      <div
        class="flex flex-col items-start justify-center absolute px-24 py-10 rounded-lg bg-gray-100 top-[30%] left-[40%]"
      >
        <div class="flex justify-center w-full">
          <img
            class="w-[130px] h-[130px]"
            :src="require(`@/assets/images/logos/${PROJECT_NAME}.png`)"
            :alt="`${APP_NAME} Logo`"
          />
        </div>
        <div class="mt-4 mb-7 text-center w-full">
          <strong class="text-2xl">Welcome, </strong>
          <p class="text-xs font-mulish">
            Enter your 4 digit passcode to proceed.
          </p>
        </div>
        <div
          id="inputs"
          class="flex gap-3 w-[250px] items-center justify-center"
        >
          <input
            v-for="i in 4"
            :key="i"
            class="input h-[60px] text-center"
            type="text"
            inputmode="numeric"
            maxlength="1"
            @input="input($event)"
            @keyup="keyup($event)"
          />
        </div>
        <div class="w-full mt-6">
          <button
            @click="verifyotp"
            :disabled="!otpReady || processing"
            class="w-full bg-oneGreen text-white font-semibold text-sm py-3 rounded flex justify-center items-center"
          >
            Proceed
            <spinner
              v-if="processing"
              class="w-5 h-5"
              color="bg-gray-800"
            ></spinner>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "OtpModal",

  data() {
    return {
      otp: "",
      processing: false,
    };
  },
  computed: {
    otpReady() {
      return this.otp.length === 4;
    },
  },
  async created() {
    await this.verifyotp();
  },
  methods: {
    handleClick() {
      this.$emit("handleClick", this.otp);
    },
    input(e) {
      const target = e.target;
      const val = target.value;

      if (isNaN(val)) {
        target.value = "";
        return;
      }

      if (val !== "") {
        this.otp += val;
        const next = target.nextElementSibling;

        if (next) {
          next.focus();
        }
      }
    },
    keyup(e) {
      const target = e.target;
      const key = e.key.toLowerCase();

      if (key === "backspace" || key === "delete") {
        const otpVal = this.otp.split("");
        otpVal.pop();
        this.otp = otpVal.join("");

        target.value = "";
        const prev = target.previousElementSibling;
        if (prev) {
          prev.focus();
        }
      }
    },
    async verifyotp() {
      try {
        this.processing = true;
        const res = await this.$http.post(
          "/adhoc/validate-token",
          { accessCode: this.otp, accessLinkCode: this.$route.params.token },
          { headers: { noauth: true } }
        );

        this.processing = false;
        if (!res) {
          return;
        }

        const { data } = res.data;
        this.name = `${data.firstName} ${data.lastName}`;

        this.$store.commit("admin/setOtpSession", {
          timestamp: Number(this.moment().add(5, "years").format("x")),
          names: this.name,
        });
      } catch (error) {
        console.log({ error });
      }
    },
  },
};
</script>
