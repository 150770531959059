<script>
export default {
  name: "DashboardStaffCard",
  props: {
    firstName: String,
    lastName: String,
    verificationCode: String,
    photograph: String,
    phone: String,
  },
  data() {
    return {};
  },
  methods: {
    handleClick() {
      this.$emit("handleClick");
    },
  },
};
</script>

<template>
  <div
    class="h-[136px] w-[114px] flex flex-col items-center justify-center bg-white shadow rounded cursor-pointer"
    @click="handleClick"
  >
    <img
      v-if="!photograph"
      src="@/assets/images/null_female.png"
      class="h-14 w-14 rounded-full object-cover mb-2"
      alt=""
    />
    <img v-else :src="photograph" class="h-14 w-14 rounded-full mb-2" alt="" />
    <span class="text-xs">{{ firstName }}</span>
    <span class="text-xs">{{ lastName }}</span>
  </div>
</template>

<style scope></style>
