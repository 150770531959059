<script>
import { Icon } from "@iconify/vue2";
import { useNumberFormatter } from "@/services/utils";

export default {
  name: "DashboardSmallCards",
  components: { Icon },
  props: {
    iconName: String,
    iconBg: String,
    iconColor: String,
    text: String,
    count: Number,
  },
  data() {
    return {};
  },
  computed: {
    formatedNumber() {
      return useNumberFormatter(this.count);
    },
  },
  methods: {},
};
</script>

<template>
  <div
    class="bg-white rounded-md h-[208px] w-full p-5 flex flex-col justify-around items-start shadow"
  >
    <span
      :class="`${iconBg} h-10 w-10 rounded-full flex items-center justify-center`"
    >
      <Icon :icon="iconName" :class="`${iconColor}`" :inline="true" />
    </span>
    <strong class="text-lg text-mdgray">
      {{ text }}
    </strong>
    <strong v-if="count" class="text-[40px] text-idBlued">
      {{ formatedNumber }}
    </strong>

    <strong v-else class="text-2xl text-idBlued"> 0 </strong>
  </div>
</template>

<style scoped></style>
