<template>
  <section
    class="w-full bg-white rounded shadow p-5 mt-10 min-h-[320px] relative"
  >
    <LoadOverlay v-if="getting" class="absolute inset-0 h-full w-full" />

    <AuthorizedStaffHeader
      @handleSelectMda="searchByMDA"
      @handleSelectRecord="searchByCategory"
      @handleSearch="search"
      :staff-count="count"
    />
    <StaffList :data="staffData" />

    <Transition name="bounce">
      <SearchOverlay
        v-if="hideShowSearch"
        @handleBack="goBack"
        :data="staffData2"
        :staff-count="searchCount"
      />
    </Transition>
  </section>
</template>

<script>
import AuthorizedStaffHeader from "@/components/dashboard/element/authorized/AuthorizedStaffHeader.vue";
import StaffList from "@/components/dashboard/element/authorized/StaffList.vue";
import SearchOverlay from "./SearchOverlay.vue";
import LoadOverlay from "@/components/app/LoadOverlay.vue";
export default {
  name: "DashbaordStaffSection",
  components: {
    SearchOverlay,
    StaffList,
    AuthorizedStaffHeader,
    LoadOverlay,
  },
  data() {
    return {
      hideShowSearch: false,
      staffData: [],
      staffData2: [],
      getting: true,
      count: 0,
      searchCount: 0,
    };
  },
  async created() {
    const data = await this.fetchAuthorizedRecords();
    this.count = data.length;
    this.staffData = data.completedRecords;
  },
  methods: {
    goBack() {
      this.hideShowSearch = false;
    },
    async searchByMDA(val) {
      const data = await this.fetchAuthorizedRecords("mdaId", val);
      this.count = data.length;
      this.staffData = data.completedRecords;
    },
    async searchByCategory(val) {
      const data = await this.fetchAuthorizedRecords("staffTypeId", val);
      this.count = data.length;
      this.staffData = data.completedRecords;
    },
    async search(val) {
      console.log({ val });

      const returnVal = await this.fetchAuthorizedRecords("search", val);

      if (!returnVal) {
        this.$swal({
          text: "Staff Not Found",
          icon: "error",
        });

        return;
      }
      this.staffData2 = returnVal.completedRecords;
      this.searchCount = returnVal.length;

      this.hideShowSearch = true;
    },
    //mdaId staffTypeId verificationCode
    async fetchAuthorizedRecords(filterName = undefined, val = undefined) {
      this.getting = true;
      try {
        const res = await this.$http.get("/adhoc/recent-records", {
          headers: { noauth: true },
          params: {
            [filterName]: typeof val === "object" ? undefined : val,
          },
        });

        this.getting = false;
        if (!res) {
          return [];
        }

        const { data } = res.data;

        if (data.length === 0) {
          return false;
        }

        // this.count = data.length;

        // return data.completedRecords;
        return data;
      } catch (error) {
        console.log({ error });
      }
    },
  },
};
</script>

<style scoped>
.bounce-enter-active {
  animation: bounce-in 0.4s;
}
.bounce-leave-active {
  animation: bounce-in 0.4s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
