<template>
  <div class="flex justify-between items-center">
    <div class="">
      <span class="mr-2"
        >Staff List: <span>({{ staffCount }})</span></span
      >
      <!-- <CustomSelect title="Months" :options="months" @input="selectMonth" /> -->
    </div>
    <div class="grow">
      <div class="flex justify-around items-center w-full gap-4">
        <span> View Staff Category By: </span>

        <CustomSelect
          title="Records"
          :options="category"
          @input="selectRecords"
        />
        <CustomSelect title="MDAs" :options="mdas" @input="selectMda" />
      </div>
    </div>
    <div class="text-right">
      <div class="flex justify-end">
        <CustomeSearch @handleInput="handleSearch" class="" />
      </div>
    </div>
  </div>
</template>

<script>
import CustomSelect from "@/components/form/CustomSelect.vue";
import CustomeSearch from "@/components/dashboard/element/CustomSearch.vue";
export default {
  name: "StaffListHeader",
  components: { CustomSelect, CustomeSearch },
  props: {
    staffCount: { type: Number, required: true },
  },
  data() {
    return {
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    };
  },
  computed: {
    mdas() {
      const mdas = this.$store.state.staff.mdas.map((el) => {
        return {
          label: el.name,
          value: el._id,
        };
      });

      return [{ label: "All Mdas", value: "" }, ...mdas];
    },
    category() {
      const category = this.$store.state.admin.staffTypes.map((el) => {
        return {
          label: el.name,
          value: el._id,
        };
      });

      return [{ label: "All Category", value: "" }, ...category];
    },
  },
  methods: {
    selectMonth(month) {
      this.filters.month = month;
    },
    selectMda(mda) {
      this.$emit("handleSelectMda", mda);
    },

    selectRecords(record) {
      this.$emit("handleSelectRecord", record);
    },
    handleSearch(val) {
      this.$emit("handleSearch", val);
    },
  },
};
</script>

<style scope></style>
