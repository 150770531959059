<template>
  <div id="holder">
    <div
      v-if="getting"
      class="absolute inset-0 h-full w-full bg-black/30 z-2222 flex items-center justify-center"
    >
      <spinner class="h-20 w-20"></spinner>
    </div>

    <template>
      <section class="flex justify-between items-center px-3">
        <MidTab v-model="activeTab" />

        <MidSelect @mda="findMDa" @reset="resett" />
      </section>

      <section class="w-full overflow-x-scroll">
        <table class="min-w-[2100px] font-mulish mt-9">
          <tr>
            <th>{{ title }}</th>
            <th colspan="3" class="tdark">Staff Count</th>
            <th class="w-4 bg-white"></th>
            <th colspan="3" class="tdark">Total Gross</th>
            <th class="w-4 bg-white"></th>
            <th colspan="3" class="tdark">Total Net</th>
            <th class="w-4 bg-white"></th>
            <th colspan="3" class="tdark">
              New {{ mode === "pension" ? "Pensioners" : "Staff" }}
            </th>
            <th class="w-4 bg-white"></th>
            <th colspan="3" class="tdark">
              {{ mode === "pension" ? "Deceased Pensioners" : "Retired Staff" }}
            </th>
          </tr>

          <tr class="w-full">
            <td class=""></td>
            <template v-for="r in 5">
              <td
                v-for="(hhd, k) in tdHeading"
                :key="`${r}-${k}`"
                class="td px-2"
              >
                <div
                  class="text-sm text-center border rounded bg-white text-dkgray"
                >
                  {{ hhd }}
                </div>
              </td>
              <td class="w-4 bg-white" :key="r"></td>
            </template>
          </tr>

          <tr v-for="(item, y) in datad" :key="y">
            <td
              :class="`uppercase text-xs font-bold truncate px-4 py-1.5 ${
                y == 'total' ? 'tdd' : ''
              }`"
            >
              <span class="text-litgray text-lg">&#x2022;</span>{{ y }}
            </td>

            <template v-for="(itm, k) in modeHeading">
              <td
                v-for="(hd, g) in item[itm]"
                :key="`${hd}-${itm}-${g}`"
                :class="`pl-3 py-3 ${y == 'total' ? 'tdd' : 'td'}`"
              >
                <div v-if="g != 2" class="text-dkgray w-full">
                  <span v-if="k == 1 || k == 2">₦</span>
                  {{ formatNumber(hd) }}
                </div>

                <div
                  v-else
                  :class="`flex justify-between items-center w-full ${
                    hd >= 0 ? 'text-[#149F92]' : 'text-[#ED4C5C]'
                  }`"
                >
                  <span>
                    <span v-if="k == 1 || k == 2">₦</span>
                    {{ formatNumber(hd) }}</span
                  >
                  <span v-if="hd > 0"> &#11105; </span>
                  <span v-if="hd < 0">&#11107;</span>
                </div>
              </td>
              <td
                :class="`w-4 ${y == 'total' ? 'tdd' : 'bg-white'}`"
                :key="k"
              ></td>
            </template>
          </tr>
        </table>
      </section>
    </template>
  </div>
</template>

<script>
import MidTab from "@/components/dashboard/element/MidTab.vue";
import MidSelect from "@/components/dashboard/element/MidSelect.vue";
export default {
  name: "MidPaySlip",

  components: {
    MidTab,
    MidSelect,
  },

  data() {
    return {
      activeTab: "staff",
      mode: "staff", //staff, pension, mda
      datad: {},
      getting: false,
      staffHeadings: [
        "staffCount",
        "totalGross",
        "totalNet",
        "newStaffCount",
        "retiredStaffCount",
      ],
      pensionHeadings: [
        "staffCount",
        "totalGross",
        "totalNet",
        "newPensionerCount",
        "deceasedPensionerCount",
      ],
    };
  },

  watch: {
    activeTab(nv) {
      this.mode = nv;
      this.getData();
    },
  },

  computed: {
    title() {
      switch (this.mode) {
        case "pension":
          return "Pension";
        case "mda":
          return "MDA";
        default:
          return "StaffType";
      }
    },
    tdHeading() {
      return [
        this.moment().subtract(2, "months").format("MMM"),
        this.moment().subtract(1, "months").format("MMM"),
        "Difference",
      ];
    },
    modeHeading() {
      switch (this.mode) {
        case "pension":
          return this.pensionHeadings;
        default:
          return this.staffHeadings;
      }
    },
  },

  created() {
    this.getData();
  },

  methods: {
    async findMDa(mda) {
      if (mda.mda) {
        this.mode = "mda";
        this.getData(mda);
      } else {
        this.mode = "staff";
        this.activeTab = "staff";
      }
    },

    resett() {
      this.mode = "staff";
      this.getData();
    },

    async getData(obj = null) {
      try {
        this.getting = true;

        let urlParam = `?mode=${this.mode}`;
        urlParam += obj && obj.mda ? `&mda=${obj.mda}` : "";

        const url = `/adhoc/payslip${urlParam}`;

        const res = await this.$http.get(url, {
          headers: { noauth: true },
        });

        this.getting = false;
        if (!res) {
          return;
        }

        const { data } = res.data;
        this.datad = data;
      } catch (error) {
        console.log({ error });
      }
    },
    formatNumber(num, precision = 2) {
      if (isNaN(Number(num))) return num;

      const ft = new Intl.NumberFormat("en");

      return ft.format(num.toFixed(precision));
    },
  },
};
</script>

<style scoped>
div#holder {
  @apply bg-white rounded-lg border-[0.5px] border-dtgray pt-3 w-full relative min-h-[300px];
}

th {
  @apply text-sm font-bold text-dkgray py-2;
}

/* th:first-of-type {
  @apply text-left px-4;
} */

th.tdark {
  @apply bg-litergray rounded-t border-white;
}

/* .border-spacing-2 {
  border-spacing: 1.2rem 0rem;
} */

td.td {
  @apply text-dkgray bg-litergray;
}
td.tdd {
  @apply bg-[#E0E0E0] font-bold text-black;
}
</style>
