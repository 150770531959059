<template>
  <div class="flex gap-7 items-center">
    <button
      v-if="mda"
      @click="resetr"
      class="border border-blue-700 text-blue-600 rounded bg-blue-50 py-2 px-6 mt-3 flex items-center gap-2"
    >
      <span class="text-xl">&#8592;</span>
      Return to Home
    </button>

    <v-select
      id="mdaId"
      label="name"
      :reduce="(b) => b.name"
      :options="$store.state.staff.mdas"
      v-uppercase
      v-model="mda"
      class="selecte w-[280px]"
      @input="$emit('mda', { mda })"
      placeholder="-- Select MDA --"
    ></v-select>
  </div>
</template>

<script>
export default {
  name: "MidSelect",

  data() {
    return {
      mda: null,
    };
  },

  methods: {
    resetr() {
      this.mda = null;
      this.$emit("reset");
    },
  },
};
</script>

<style scoped>
.selecte {
  height: 40px !important;
}
.selecte .vs__search::placeholder {
  font-weight: 700 !important;
  font-size: 0.75rem !important;
  line-height: 0.875rem !important;
  color: #1f1d1d !important;
}
.selecte .vs__dropdown-menu {
  background: #f9f9f9;
  color: #757474;
  font-size: 0.7rem !important;
  line-height: 0.9rem;
  font-family: "Mulish";
  text-transform: uppercase;
  font-variant: small-caps;
  width: 300px !important;
}
.vs__dropdown-toggle {
  padding: 0rem !important;
}
</style>
