<template>
  <div>
    <header>
      <Logo />
    </header>

    <main
      class="py-22 px-4 lg:px-10 lg:py-22 bg-litergray min-h-screen relative"
    >
      <TopSection :title-and-name="name" />

      <MidPaySlip class="mt-8" />

      <AuthorizedSection />
    </main>

    <OtpAuthModal v-if="$store.getters['admin/adHocOtpRefresh']" />
  </div>
</template>

<script>
import Logo from "@/components/app/Logo.vue";
import TopSection from "@/components/dashboard/TopSection.vue";
import MidPaySlip from "@/components/dashboard/MidPaySlip.vue";
import AuthorizedSection from "@/components/dashboard/AuthorizedSection.vue";
import OtpAuthModal from "@/components/dashboard/OtpAuth.vue";
export default {
  name: "AdhocDashboard",
  components: {
    Logo,
    TopSection,
    MidPaySlip,
    OtpAuthModal,
    AuthorizedSection,
  },
  data() {
    return {
      selectedValue: null,
      getting: false,
      processing: false,
      name: "",
      staffData: [],
    };
  },

  created() {
    this.$store.dispatch("staff/getMdas");
    this.$store.dispatch("admin/getStaffTypes");
  },
  methods: {},
};
</script>

<style scoped>
header {
  @apply flex justify-between px-4 lg:px-7 py-[1.0625rem] lg:py-4 fixed top-0 left-0 right-0;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
  z-index: 100;
}
</style>
